import "core-js/stable"
import dompolyfill from '~/common/js/modules/dom-polyfill.js'

import "./standalone.scss"
import rnn from '~/common/js/rnn'
import main from './js/main'

dompolyfill();
window.rnn = rnn

document.addEventListener("DOMContentLoaded", () => {
  main()
});

// Hot Module reloading
if(typeof module !== 'undefined' && module.hot){
  module.hot.accept('./js/main', function(){
    console.log('----------- main.js updated -----------')
    main()
  })
  // Capture and Re-insert siteconfig on hot reload
  let siteConfig;
  const _init = rnn.init
  window.rnn.init = cfg => siteConfig = cfg && _init(cfg)
  module.hot.accept('~/common/js/rnn', function(){
    console.log('----------- rnn.js updated -----------', siteConfig)
    window.rnn.init(siteConfig)
  })
}
