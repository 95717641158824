import $ from 'jquery';
import typeahead from "typeahead.js";
import Bloodhound from "bloodhound-js";


export default class Header {
  constructor(searchInput, formExpand, mobileSearchTitle, mobileSearchInput, closeMobileForm, searchFormEl, searchIconEl, documentBodyEl) {
    this.searchInput = searchInput;
    if(!this.searchInput) {
      return;
    }

    this.formExpand = formExpand;
    this.mobileSearchTitle = mobileSearchTitle;
    this.mobileSearchInput = mobileSearchInput;
    this.closeMobileForm = closeMobileForm;
    this.searchFormEl = searchFormEl;
    this.searchIconEl = searchIconEl;
    this.documentBodyEl = documentBodyEl;
    this.keywordInputEl = this.searchFormEl.querySelectorAll('input[name="keywords"]')[0];
    this.classificationSelectEl = this.searchFormEl.querySelectorAll('select[name="category"]')[0];

    this.$locationEl = $('#locations');
    this.$localityInputEl = $('#typeahead-locality');
    this.locationElClose = this.searchFormEl.querySelector("[data-clear-input]") || '';
    this.initTypeaheadHeader();
    this.addEventListeners();
  }

  addEventListeners() {

    window.addEventListener('resize', event => this.onWindowResize(event));

    this.searchInput.addEventListener("click", event => this.showSearchForm());

    this.closeMobileForm.addEventListener("click", event => this.hideSearchForm());

    this.$locationEl.bind('typeahead:select', (ev, suggestion) => {
     this.$localityInputEl.val(suggestion.slug);
    });


    // for when the user clicks the X - clear event
    this.$locationEl.on('search', event => {
      if (!this.$locationEl.val()) {
        this.$localityInputEl.val("");
      }
    });

    if (this.locationElClose) {
      this.locationElClose.addEventListener("click", () => {
        this.$locationEl.val("");
        this.$localityInputEl.val("");
        this.conditionallyHideClearIcon();
      });
    };
    this.conditionallyHideClearIcon();
    this.toggleRequiredKeywords();

    this.$locationEl[0].addEventListener("change", (ev) => {
      this.toggleRequiredKeywords();
    });

    this.$locationEl[0].addEventListener("input", () => {
      this.conditionallyHideClearIcon();
    });

    this.classificationSelectEl.addEventListener("change", () => {
      this.toggleRequiredKeywords();
    });

    this.keywordInputEl.addEventListener("input", () => {
      this.toggleRequiredKeywords();
    });
  }

  conditionallyHideClearIcon(e) {
    var clearicon = this.locationElClose || '';

    if (clearicon) {
      if (this.$locationEl.val()) {
        clearicon.style.display = 'block';
      }
      else {
        clearicon.style.display = 'none';
      }
   }
  }

  onWindowResize(e) {
    if(window.innerWidth > 767) {
      this.hideSearchForm();
    }
  }

  toggleRequiredKeywords() {
     const KEYWORD_MESSAGE = 'Please type a keyword or choose a category or a location to browse';

      // check if theres a value - which ir probably have - and
      // remove the required from classification
      this.keywordInputEl.required = !Boolean(this.classificationSelectEl.value) && !Boolean(this.$locationEl[0].value);;

      if (this.keywordInputEl.required && !this.keywordInputEl.value && !this.$locationEl[0].value) {
        this.keywordInputEl.setCustomValidity(KEYWORD_MESSAGE);
      } else {
        this.keywordInputEl.setCustomValidity("");
      }
  }

  showSearchForm() {
    if(window.innerWidth < 767) {
      this.formExpand.classList.add("display-form");
      this.mobileSearchTitle.classList.add("flex-center");
      this.mobileSearchInput.classList.add("input-always-show-open");
      this.searchFormEl.classList.add("min-form-height");
      this.searchInput.classList.remove("js-toggle-search-style");
      this.searchIconEl.classList.remove("js-search-icon");
      this.documentBodyEl.classList.add("search-showing");
    }
  }

  hideSearchForm() {
    this.formExpand.classList.remove("display-form");
    this.mobileSearchTitle.classList.remove("flex-center");
    this.mobileSearchInput.classList.remove("input-always-show-open");
    this.searchFormEl.classList.remove("min-form-height");
    this.searchInput.classList.add("js-toggle-search-style");
    this.searchIconEl.classList.add("js-search-icon");
    this.documentBodyEl.classList.remove("search-showing");
  }

  initTypeaheadHeader() {
    if (!window.BSS || !window.BSS.enableRadiusSearch) {
      return;
    }

    var baseUrl = window.BSS.baseUrl || '/';
    const locationUrl = baseUrl + 'location/typeahead.json?query=%QUERY&limit=25&types=po&return=slug&format=sensible&return_type=list';
    var locations = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: locationUrl,
        wildcard: '%QUERY',
        transform: function(response)  {
          //todo: have that already indexed when the requirements are established
          return response.map(function(suggestion) {
              suggestion.sensible_address = suggestion.sensible_address.replace(/, Australia/g,'');
              suggestion.sensible_address = suggestion.sensible_address.replace(/Queensland/g,'QLD');
              suggestion.sensible_address = suggestion.sensible_address.replace(/New South Wales/g,'NSW');
              suggestion.sensible_address = suggestion.sensible_address.replace(/Northern Territory/g,'NT');
              suggestion.sensible_address = suggestion.sensible_address.replace(/Australian Capital Territory/g,'ACT');
              suggestion.sensible_address = suggestion.sensible_address.replace(/South Australia/g,'SA');
              suggestion.sensible_address = suggestion.sensible_address.replace(/Tasmania/g,'TAS');
              suggestion.sensible_address = suggestion.sensible_address.replace(/Western Australia/g,'WA');
              return suggestion;
          });
        }
      },
    });

    this.$locationEl.typeahead({
      minLength: 3,
      highlight: true,
      hint: false,

      classNames: {
        input: 'Typeahead-input',
        hint: 'Typeahead-hint',
        selectable: 'Typeahead-selectable'
      }
    },
    {
      name: 'locations',
      display: 'sensible_address',
      source: locations,
      limit: 8,
    });
  }
}
