export default function attachTogglingOfAccordion(selector) {
  for (let accordion of document.querySelectorAll(selector)) {
    accordion.addEventListener('click', (evt) => {
      let accordionTitle = accordion.querySelector('.accordion-title');
      let accordionBody = accordion.querySelector('.accordion-body');
      let accordionBodyFollow = null;
      if (accordion.nextElementSibling) {
        accordionBodyFollow = accordion.nextElementSibling.querySelector('.accordion-body-follow');
      }
  
      if (!accordionTitle && !accordionBody) {
        accordionTitle = accordion.parentNode.querySelector('.accordion-title');
        accordionBody = accordion.parentNode.querySelector('.accordion-body');
      }

      if (accordionBody) accordionBody.classList.toggle('show');
      if (accordionBodyFollow) accordionBodyFollow.classList.toggle('show');
      if (accordionTitle) accordionTitle.classList.toggle('show');
    });
  }
}