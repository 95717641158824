import { AFFLIATE_DEFAULTS} from './constants.js';
import {addQueryString} from './../modules/utils.js';

export default function initCrossDomainLinking() {
    const getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    };

    const queryParams = getQueryStringParams(window.location.search.substring(1));

    for (let param of AFFLIATE_DEFAULTS) {
        if (param in queryParams) {
            // We generally pass queryparameters around from  page to page now
            // but just in case we ll store them here
            window.sessionStorage.setItem(param, queryParams[param]);
        }
    }

    for (let link of document.querySelectorAll('a')) {
        if (link.href.indexOf('newsconcierge.com.au') !== -1) {
            link.href = addQueryString(link.href, affiliateQS());
            console.log(link.href);
        }
    }
}


export function affiliateQS() {

    const affilateLinks = [];
    let affilateLinksStr = '';

    for (let param of AFFLIATE_DEFAULTS) {
      if (window.sessionStorage.getItem(param) !== null) {
        affilateLinks.push(param + '=' + window.sessionStorage.getItem(param));
      }
    }
    return affilateLinks.join('&');
}


