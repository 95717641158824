import m from 'mithril'
import u from 'umbrellajs'
import { assoc, map } from 'rambda'
import { debounce } from 'rambdax'
import RefinementSearchForm from './modules/refinement-filters/models/RefinementSearchForm'

// Main Mithril component for the refinement filters
export function RefinementComponent(){

  // Setup State
  const state = new RefinementSearchForm(window.BSS.selectedFilter)

  // Helpers
  const buttonLabel = () => state.total !== null ? `View ${state.total} Results` : 'Loading..'

  // Events
  const closeRefinement = () => {
    u('#refinement-filters').addClass('refinement-form__hide').removeClass('refinement-form__show')
    u(document.body).removeClass('refinement-showing')
  }
  const clearFilters = () => state.resetValues().syncMenu().then(m.redraw)
  const submitForm = (event) => {
    event.preventDefault()
    window.location = state.pageUrl
  }

  // Takes a Filter and returns an event handler for updating state
  const updateFilter = ({template, slug}) => ({target}) =>
    state.updateValue({template, slug, value: target.value}).syncMenu().then(m.redraw)

  return {
    oninit: function(){
      state.syncMenu().then(m.redraw)
    },
    view: () => (
      <div class="refinement-form desktop">
        { state.filters.length > 0 ? (
          <form id="refinement-filters" method="get" autocomplete="off">
            <div class="mobile-search-title">
              <h4 class="mobile-form-title">Refine search</h4>
              <span class="bss-icon-close close-mobile-form-icon" onclick={ closeRefinement }></span>
            </div>
            <div class="refinement-form__wrapper">
              { state.filters.map(filter =>
                filter.template === 'text.html'
                  ? ( <InputFacet filter={filter} updateFilter={updateFilter} /> )
                : filter.template === 'select.html' || filter.template === 'link.html'
                  ? ( <SelectFacet filter={filter} state={state} updateFilter={updateFilter} /> )
                : filter.template === 'range.html'
                  ? ( <RangeFacet filter={filter} state={state} /> )
                : filter.template === 'tree.html'
                  ? ( <TreeFacet filter={filter} state={state} /> )
                : console.error('Unknown facet template', filter) || null
              )}
            </div>
            <div class="form-footer">
              <button type="reset" class="grey" onclick={clearFilters}>CLEAR FILTERS</button>
              <button type="submit" class="primary" onclick={submitForm}>{ buttonLabel() }</button>
            </div>
          </form>
        ) : null }
      </div>
    )
  }
}

export function InputFacet({attrs: {filter, updateFilter}}){
  const updateText = filter => debounce(updateFilter(filter), 300)
  return {
    view: () => (
      <div class="form-control col-grid-layout">
        <label>{ filter.label }</label>
        <input type="text" name={ filter.slug } defaultValue={ filter.value } onkeyup={ updateText(filter) } />
      </div>
    )
  }
}

export function SelectFacet({attrs: {filter, updateFilter, state}}){
  return {
    onbeforeupdate: () => {
      filter = state.filters.find(x => x.slug === filter.slug)
    },
    view: () => (
      <div class="form-control col-grid-layout">
        <label>{ filter.label }</label>
        <select name={filter.slug} onchange={updateFilter(filter)}>
          { filter.children.map(({slug, name}) => (
            <option key={`${filter.label}.${slug}`} value={slug} selected={state.get(filter) === slug ? true : null}>{ name }</option>
          )) }
        </select>
        <span class="bss-icon-chevron-down drop-down-arrow"></span>
      </div>
    )
  }
}

// @TODO: Might be bad passing the whole state through like this..
export function RangeFacet({attrs: {filter, state}}){
  const updateRange = ({template, slug}) => debounce(event => {
    const value = u(event.target).parent().find('input').array(x => x).map(x => x.value).join('-')
    return state.updateValue({template, slug, value}).syncMenu().then(m.redraw)
  }, 300)
  return {
    view: () => (
      <div class="form-control col-grid-layout">
        <label>{ filter.label }</label>
        <div class="facet-input-field ranges" data-prefix='$'>
          <input type="number" ref="min"
                 min={0}
                 placeholder="From"
                 defaultValue={ state.get(filter) && state.get(filter)[0] }
                 onkeyup={updateRange(filter)} />
          <div />
          <input type="number" ref="max"
                 min={0}
                 placeholder="To"
                 defaultValue={ state.get(filter) && state.get(filter)[1] }
                 onkeyup={updateRange(filter)} />
        </div>
      </div>
    )
  }
}

export function TreeFacet({attrs: {filter, state}}){

  // TreeSelector State
  let treeState = {}
  let filtered = filter.children
  // The current user input value, not the true hidden value
  let inputValue = undefined
  // The "nice" looking value for displaying when user is not directly interacting
  let valueLabel = state.getLabel(filter.slug)

  const byInput = child => child.label.toLowerCase().includes(inputValue.toLowerCase())
  const filterRecords = map(s => ({
    ...s,
    children: Array.isArray(s.children) ? s.children.filter(byInput) : undefined
  }))

  const toggleTree = id => {
    treeState = assoc(id, treeState[id] ? false : true, treeState)
    m.redraw()
  }
  const recordClass = id => state.values[filter.slug] === id ? 'record active' : 'record'
  const highlight = text => inputValue
        ? text.replace(new RegExp(inputValue, 'ig'), '<b>$&</b>')
        : text

  const updateTree = ({template, slug}, value, label) => () => {
    inputValue = undefined
    valueLabel = label
    document.activeElement.blur() // Close the menu
    state.updateValue({template, slug, value}).syncMenu().then(m.redraw)
  }

  const handleTreeInput = async ({target}) => {
    inputValue = target.value.replace(/[^0-9a-z ]/gi, '')
    await state.buildSearchFilters()
    filtered = filterRecords(filter.children)
    m.redraw()
  }
  const handleFocus = () => {
    inputValue = ""
  }
  const handleBlur = ({relatedTarget}) => {
    if(u(relatedTarget).hasClass('dropdown')) return false;
    if(inputValue !== '') return false;
    inputValue = undefined;
  }

  return {
    onbeforeupdate: () => {
      // On reset, we need to resync the inputValue
      valueLabel = state.getLabel(filter.slug)
      filtered = state.filters.find(x => x.slug === filter.slug).children
      if(inputValue){ filtered = filterRecords(filtered) }
    },
    view: () => (
      <div class="form-control dropdown-outer col-grid-layout">
        <label>{ filter.label }</label>
        <input
          type="text"
          name={ filter.slug }
          placeholder={ valueLabel }
          value={ typeof inputValue !== 'undefined' ? inputValue : valueLabel }
          onkeyup={handleTreeInput}
          onfocus={handleFocus}
          onblur={handleBlur} />
        <ul class="dropdown" tabindex={-1}>
          { filtered.map(({id, label, children}) =>
            children && children.length > 0
              ?(<li key={`${filter.label}.${id}`} class={ treeState[id] || (inputValue+'').length > 0 ? 'open' : '' }>
                  <span class="label-toggle" onclick={() => toggleTree(id)}>{ label }</span>
                  <ul>
                    { children.map(({id, label}) => (
                      <li key={`${filter.label}.${id}`} class={ recordClass(id) } onclick={ updateTree(filter, id, label) }>
                        { m.trust(highlight(label)) }
                      </li>
                    ))}
                  </ul>
                </li>)
              : children === undefined
                // No children, this is a top level record
                ? (<li key={`${filter.label}.${id}`} class={ recordClass(id) } onclick={ updateTree(filter, id, label) }>
                     { m.trust(highlight(label)) }
                   </li>)
                // When all children filtered, render null element
                : (<li key={`${filter.label}.${id}`} />)
          ) }
        </ul>
        <span class="bss-icon-chevron-down drop-down-arrow"></span>
      </div>
    )
  }
}

export default function RefinementFilters(){
  const $filters = u('#vue-refinement-filter').first()
  if(!$filters) return;
  m.mount($filters, RefinementComponent())
}
