import axios from 'axios'
import { pipe, filter, map, when } from 'rambda'
import { isRefinedFacet } from '../utils/functions'

// Construct the url params for the menu/json query, example:
// ?classification=motoring&location=all-locations&refined_facets=make-holden&refined_facets=body-type-suv
// @NOTE: refined_facets could also be grouped by comma, eg: ?refined_facets=make-holden,body-type-suv
export const getQueryParams = pipe(
  Object.entries, // Pair<String, String>
  filter(pair => pair[1]),
  map(pair => [pair[0], pair[0] === 'classification' ? pair[1].split('/').pop() : pair[1]]),
  map(pair => [isRefinedFacet(pair[0]) ? 'refined_facets' : pair[0], pair[1]]),
  opts => new URLSearchParams(opts),
)

//    parseFacet :: Any -> Facet
const parseFacet = (data, key) => ({
  // name, slug and count should be required for every facet object
  slug: data.slug || data.facet_count?.item.full_slug || key,
  name: data.name || data.label || data.facet_count?.item.name || key,
  count: data.count || data.facet_count?.count || -1,
  // Top level Facets will have children to also be parsed as a Facet
  children: data.children?.map(parseFacet)
    // locality facets come in pairs with their key as the parent name..
    || Array.isArray(data) && data.map(pair => parseFacet({ ...pair[0], count: pair[1] }))
    // Otherwise no children
    || undefined,
  // Template for top level refined facets
  template: data.template || undefined,
})

//    parseFacets :: Array[Any] | null -> Array[Facet] | null
const parseFacets = when(facet => facet != null, map(parseFacet))

// Takes the response from `/menu/json/` and returns the `menuJson` object used in RefinementSearchForm
export const parseMenu = ({ data }) => ({
  classification: {
    slug: 'classification',
    name: 'All Categories',
    // Use -1 to say we shouldn't filter this node, it has results, we just
    // don't know how many so don't show the user
    counts: -1,
    children: data.classification_facets.map(parseFacet),
  },
  selectedClassification: parseFacets(data.selected_classification_facets),
  locality: {
    slug: 'suburb',
    name: 'All Locations',
    count: -1,
    children: Object.values(map(parseFacet, data.locality_facets)).filter(x => x && !Array.isArray(x))
  },
  location: data.location_facets,
  refined: data.refined_facets.core_properties_structured.map(parseFacet),
  total: data.total,
  hasPriceFacets: data.has_price_facets
})

// Define a local cache of the results to avoid duplicate requests when querying menu data
let MENU_CACHE = {}

// Make a a query to /menu/json to get the updated menu data based on the provided filter values
export const getMenuData = async opts => {

  const key = getQueryParams(opts).toString()
  if (!MENU_CACHE[key]) {
    MENU_CACHE[key] = 'pending'
    MENU_CACHE[key] = await axios.get(`${window.BSS.ajaxUrl}?${getQueryParams(opts)}`).then(parseMenu)
  }

  // Handle multiple requests while the fetch is still pending
  for (let limit = 50; limit > 0; limit--) {
    if (MENU_CACHE[key] !== 'pending') { break }
    await new Promise(res => setTimeout(res, 100))
  }
  if (MENU_CACHE[key] === 'pending') {
    console.error('Timeout expired waiting for MENU_CACHE', opts)
    MENU_CACHE[key] = undefined
  }

  return MENU_CACHE[key]
}

export default { getQueryParams, getMenuData }
