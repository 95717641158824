import * as commonConstants from "~/common/js/constants"

// ********************************************************
//   Centralised Constant repository for BSS
// ********************************************************

// Body classes to add for when various UI elements are toggled
export const SHOW_REFINEMENT_CLASS = commonConstants.SHOW_REFINEMENT_CLASS;
export const SHOW_MENU_CLASS = 'menu-showing';
export const SHOW_SEARCH_CLASS = 'search-showing';

// Mobile search
export const searchInputEl = commonConstants.searchInputEl;
export const formExpandEl = commonConstants.formExpandEl;
export const mobileSearchTitleEl = commonConstants.mobileSearchTitleEl;
export const mobileSearchInputEl = commonConstants.mobileSearchInputEl;
export const closeMobileFormEl = commonConstants.closeMobileFormEl;
export const searchFormEl = commonConstants.searchFormEl;
export const searchIconEl = commonConstants.searchIconEl;
export const documentBodyEl = commonConstants.documentBodyEl;

// Refinement filters
export const refineEl = commonConstants.refineEl;
export const drawerEl = commonConstants.drawerEl;
export const refineCloseEl = commonConstants.refineCloseEl;
export const refineOpenEl = document.querySelector('[data-interaction-refine-open]');

// Search menu
export const searchInputButtonEl = document.querySelector('[data-interaction-search-open]');
export const searchCloseEl = document.querySelector('[data-interaction-search-close]');

// params we may want to send through to newsconcierge
export const AFFLIATE_DEFAULTS = window.referralLinks || ['sourceCode', 'gclid'];
