import { assoc, map, pick, lensPath, lens, view, omit, chain, mergeAll, pipe } from 'rambda'
import { getRangeValues } from '../utils/functions'
import { getMenuData } from '../services/RefinementAPI'
import DOMPurify from 'dompurify';

// Settings
let BSS = {},
      // Filters facet results (Select/Tree) that have a count of 0
      ENABLE_COUNT_FILTERING = true,
      // Used on whitelabel when locking the down the radius search?
      ENABLE_RADIUS_SEARCH = false;

//    filterCount :: Facet -> Facet
const filterCount = facet => ({
  ...facet,
  children: facet.children
    ? facet.children.filter(c => c.count || c.count === -1).map(filterCount)
    : undefined
})

//           getEnhancedCounts :: (Lens, RefinementSearchForm) -> Facet
// Takes a lens pointing to a top level facet within a menuJson Object and returns
// the results with correct counts after querying
export const getEnhancedCounts = async (facetLens, {menuJson, values, topClassification}) => {
  // First use the facetLens to get the top level facet
  const facet = view(facetLens, menuJson)

  // Restrict queries from clearing too much if we otherwise end up performing an invalid
  // "root query" for everything, which will 404 in prod/staging
  const facetSlug = facet.slug === topClassification ? 'classification' : facet.slug
  let facetDefault = facet.slug === topClassification ? topClassification : null
  // If no classification is set, we will pin to suburb instead
  if(facetSlug === 'suburb' && !values['classification'] && values['suburb'] ){ facetDefault = values['suburb'] }

  // Then get the menuJson that without this facet's filter selected
  const menu = values[facetSlug]
        ? await getMenuData(assoc(facetSlug, facetDefault, values))
        : await getMenuData(values)

  // Use the lens again to get the same facet from the new menuJson, otherwise set 0
  const countsFacet = view(facetLens, menu) || {count: 0, children: []}

  // It's probably a case of passing along the lens during the transform, or maybe this is where
  // Traversable is finially usefull?
  const correctFacet = {
    ...facet,
    count: countsFacet.count,
    children: map(child => ({
      ...child,
      count: countsFacet.children.find(c => c.slug === child.slug)?.count || 0,
      children: child.children ? map(x => ({
        ...x,
        count: countsFacet.children.find(c => c.slug === child.slug)?.children.find(c => c.slug === x.slug)?.count || 0,
      }), child.children) : undefined
    }), facet.children)
  }

  return ENABLE_COUNT_FILTERING ? filterCount(correctFacet) : correctFacet
}

//    getLabels :: Tree => Object<Slug, Name>
// Takes a tree-like structure and returns an object mapping the leaf slugs and names
const getLabels = pipe(
  chain(rec => rec?.children?.length ? getLabels(rec.children) : {[rec.slug]: rec.name}),
  mergeAll,
)

// Apply the current facet count to the name
const applyCount = facet => ({...facet, name: `${facet.name} (${facet.count})`})

const capitalize = s => s[0].toUpperCase() + s.slice(1)

export default class RefinementSearchForm {

  // Store all the refinement values that will affect the search result
  constructor(initialSelectedData) {

    // Update Settings
    BSS = window.BSS || {}
    ENABLE_COUNT_FILTERING = typeof BSS.enableCountFiltering !== 'undefined' ? BSS.enableCountFiltering : true
    ENABLE_RADIUS_SEARCH = typeof BSS.enableRadiusSearch !== 'undefined' ? BSS.enableRadiusSearch : false

    this.filters = [];
    this.menuJson = null;
    this.total = null

    // Create an object of all default refinedFacets that have been set by the HTML with the prefix
    this.initialFacetData = [...Object.entries(initialSelectedData)]
      .filter(pair => pair[0].includes('refined_facets___'))
      .reduceRight((object, pair) => assoc(pair[0].replace('refined_facets___',''), pair[1], object), {})

    // The initial values set
    this.initialSelectedData = Object.freeze({...this.initialFacetData, ...initialSelectedData})
    // @NOTE: Classification is special because we don't anchor ourselves to the
    // top of the tree and as such, the top slug appears as the category and not
    // as the facet slug.
    this.topClassification = this.initialSelectedData.classification.full_slug.split('/')[0] || undefined

    // Label Cache
    // @NOTE: Because we are half assing cross facet tree traversal at this point, i'm just
    // going to flatten all labels used in TreeFacet into a single array for lookups.
    this.labels = {
      [this.initialSelectedData.suburb?.slug]: this.initialSelectedData.suburb?.name,
      [this.initialSelectedData.classification?.full_slug]: this.initialSelectedData.classification?.name,
    }

    // Dynamic values that should be in sync with the state of the UI for VDOM redrawing purposes.
    // Default values set to undefined (difference between simply undefined!) when no value is present
    this.values = {
      ...this.initialFacetData,
      keywords: initialSelectedData.keywords || undefined,
      classification: initialSelectedData.classification.full_slug || undefined,
      // Ensure falsey and 'all-locations' equal undefined
      location: (initialSelectedData.location.slug !== 'all-locations'
                 ? initialSelectedData.location.slug
                 : 0 ) || 0,
      price: initialSelectedData.price || undefined,
      suburb: initialSelectedData.suburb.slug || 0,
      radius: ENABLE_RADIUS_SEARCH && initialSelectedData.radius !== '' ? initialSelectedData.radius : undefined,
    }
    // @HACK: Need to merge or rectify location/suburb which are being treated as basicially the same
    // within here but the backend has a different idea of what each one is.
    if(this.values.suburb === 0 && this.values.location !== 0){ this.values.suburb = this.values.location }
  }

  // Called from UI updates in order to update and then sync internal state
  updateValue({value, slug, template}){
    // If it's an empty range value, expictly empty it out
    if (template === 'range.html' && value === '-') {
      this.values[slug] = value = undefined
    } else {
      this.values[slug] = value
    }
    // @HACK Suburb is our our main way of filtering so, if we set it to undefined
    // it's confusing to the user if All Locations is not actually all locations
    if(slug === 'suburb' && value === 0){ this.values.location = value }
    return this
  }

  get({ slug, template }){
    if(template === 'range.html'){
      const vals = this.values[slug]?.split('-')
      return vals?.length ? vals : [undefined, undefined]
    }
    return this.values[slug] ? this.values[slug] : undefined
  }

  // Reset selections back clear selection state.
  resetValues() {
    this.values = {
      ...map(() => undefined, this.values),
      // If the user is already browsing a classification, we are pinning the
      // root of available options to the top level classification
      classification: this.topClassification || undefined,
      // @NOTE: We must reset back to the initialSelectedData here for keywords and radius
      // because the search form isn't apart of our little Vue club so we don't control
      // syncing our data back to it.
      keywords: this.initialSelectedData.keywords || undefined,
      radius: ENABLE_RADIUS_SEARCH && this.initialSelectedData.radius !== '' ? this.initialSelectedData.radius : undefined,
    }
    if(!this.values.classification && !this.values.keywords){ this.values.suburb = this.initialSelectedData.suburb?.slug }
    return this
  }

  // Called whenever the user has changed filters and requires a resync
  async syncMenu() {

    // Get the current Menu
    if(this.menuJson === null){
      // The initial setting of menuJson will be from a clean state to give us a complete
      // structure of all of the facets available to the top level classification
      // Keywords is also included as if it's defined we will need to also consider it as
      // apart of the top level, otherwise it will be undefined and unused
      this.menuJson = await getMenuData({
        classification: this.topClassification,
        keywords: this.values['keywords'],
        // Only pin suburb when we have no classifcation or keywords to pin
        suburb: !this.topClassification && !this.values['keywords'] ? this.values['suburb'] : undefined,
        radius: this.values['radius'],
      })
      // The HTML doesn't tell us about every refinedFacet we could use, so now is our chance to set them
      for( const refinedFacet of this.menuJson.refined ){
        this.values[refinedFacet.slug] = this.initialSelectedData[`refined_facets___${refinedFacet.slug}`] || null
      }
      this.total = this.menuJson.total

      // Parse out the most complete list of labels available
      this.labels = mergeAll([
        this.labels,
        getLabels([this.menuJson.locality]),
        getLabels([this.menuJson.classification]),
      ])

    } else {
      // On follow up requests, this will prime the dataset while informing the component of our "loading" state
      this.total = null
      this.total = (await getMenuData(this.values)).total
    }

    // The data is now in sync, we can re-render the element
    await this.buildSearchFilters();
  }

  // Resets and rebuilds the filter configuration. Serves as the main data transform
  // function that will update the filters to reflect the change in state.
  async buildSearchFilters() {
    let newFilters = []
    const addFilter = filter => newFilters = filter ? newFilters.concat(filter) : newFilters

    // Determine which set of classification/location filters we should use
    const classificationFilter = this.menuJson.selectedClassification
          ? this.addClassificationSelect()
          : this.addClassificationTree()

    const locationFilters = ENABLE_RADIUS_SEARCH
          ? this.addLocalityWithRadiusFilter()
          : Promise.all([this.addLocationFilter(), this.addLocalityWithRadiusFilter()])

    // Append each filter in the correct order before copying it onto the current filters
    addFilter(await classificationFilter)
    addFilter(await this.addPriceFilter())
    addFilter(await locationFilters)
    addFilter(await this.addRefinedFilters())

    this.filters = newFilters
    return this
  }

  async addKeywordFilter() {
    return {
      slug: 'keywords',
      value: this.values.keyword,
      template: 'text.html',
      label: 'Keywords',
      valid: true,
      initial: true,
    }
  }

  async addClassificationSelect() {
    if(this.menuJson.selectedClassification.length === 0){ return }
    const facet = await getEnhancedCounts(lensPath(['selectedClassification', 0]), this)

    // Create a record for the parent record and apply the counts
    const selections = [({slug: facet.slug, name: `All ${facet.name} (${facet.count})`})]
          .concat(facet.children.map(applyCount))

    return {
      slug: 'classification',
      value: this.values.classification,
      template: 'select.html',
      label: 'Refine Category',
      children: selections,
      initial: true,
      valid: true,
    }
  }

  // @NOTE: This can only handle 1 top level, if we need to handle more then make a psuedo top level
    // so we have something to query from?
  async addClassificationTree() {
    if(this.menuJson.classification.length === 0){ return }
    const classificationTree = await getEnhancedCounts(lensPath(['classification']), this)

    // Append the id as it's needed for tree select
    const selections = classificationTree.children.map(cat => ({
      id: cat.slug,
      label: `${cat.name} (${cat.count})`,
      children: cat.children.map(child => ({
        id: child.slug,
        label: `${child.name} (${child.count})`
      }))
    }))

    return {
      slug: 'classification',
      value: this.values.classification,
      template: 'tree.html',
      label: 'Category',
      placeholder: 'All Categories',
      children: selections,
      initial: true,
      valid: true,
    }

  }

  async addLocationFilter() {
    const locations = this.menuJson.locality.children.map(facet => ({
      ...facet[0],
      name: `${facet[0].name} (${facet[1]})`
    }))
    locations.unshift({
      slug: 'all-locations',
      name: `All Locations`,
    })

    return {
      slug: 'location',
      value: this.values.slug,
      template: 'select.html',
      label: 'Refine Location',
      children: locations,
    }
  }

  async addRefinedFilters() {
    /*
    Range Core Properties:
        for example bathroom, bedrooms, car-spaces will append the query param ?bathrooms=2-4 to the url

    BIG GOTCHA: Looks like all the range properties like bathrooms/bedrooms/etc are
    passed as ?bedrooms=2-4 but the ones that are link.html are actually passed
    through refined_facets and can be a list so for example:
        refined_facets=private-seller&refined_facets=make-honda
    I am going to work around this with the urlParamName variable
    @NOTE: Is this urlParamName still relevant? Not sure....
    */
    const getRefinedFilter = async (facet) => {
      // If the facet is selected, make sure to get the filter data without being selected
      const refinedFacet = await getEnhancedCounts(lens(m => m.refined.find(f => f.slug === facet.slug), e => e), this)
      let mergeValues = {
        label: refinedFacet.name,
        val: null,
        valid: true,
        initial: true
      }

      if (refinedFacet.template === 'range.html') {
        Object.assign(mergeValues, getRangeValues(this.values[refinedFacet.slug]))
      } else if (refinedFacet.template === 'link.html') {
        Object.assign(mergeValues, {
          value: this.values[refinedFacet.slug] || '', // Make an undefined selection always select the "default" selection
          urlParamName: 'refined_facets',
          children: [
            {
              slug: '',
              name: `All ${refinedFacet.name}`,
              count: 1 // @NOTE: This is just to prevent filtering below, since we don't actually have top level refine counts
            }, // Default selection
            ...refinedFacet.children.map(child => ({
              ...child,
              name: `${child.name} (${child.count})`,
            }))
          ]
        })
      }
      return {...refinedFacet, ...mergeValues}
    }

    return await Promise.all(map(getRefinedFilter, this.menuJson.refined))
  }

  async addPriceFilter() {
    // Some searches we dont want price range (personals/trades/etc)
    if (!this.menuJson.hasPriceFacets){
      const bssContainer = document.getElementById('bss')
      if( !bssContainer.classList.contains('no-price') ){ bssContainer.classList.add('no-price') }
      return;
    }

    return {
      slug: 'price',
      template: 'range.html',
      label: 'Price Range',
      initial: true,
      valid: true,
      ...getRangeValues(this.values.price)
    }
  }

  async addLocalityNoRadiusFilter() {
    const suburbs = map((value, key) => ({
      id: key,
      label: key,
      children: map(function(item) {
        return Object.assign(item[0], {
          id: item[0].slug,
          pk: item[0].id,
          label: `${item[0].name} (${item[1]})`,
          count: item[1]
        })
      }, value)
    }), this.menuJson.locality.children)

    return {
      slug: 'suburb',
      value: this.values.suburb,
      template: 'tree.html',
      label: 'Suburb',
      children: suburbs,
      initial: true,
      valid: true,
    }
  }

  async addLocalityWithRadiusFilter() {
    // Get the tree of localities, with the root as 'all-suburbs' (a pseduo facet)
    const localityTree = await getEnhancedCounts(lensPath(['locality']), this)

    // Append the id as it's needed for tree select
    const suburbs = localityTree.children.map(state => ({
      id: state.slug,
      label: state.name,
      children: state.children.map(child => ({
        id: child.slug,
        label: `${child.name} (${child.count})`
      }))
    }))

    // *A wild locality has appeared*
    //
    // Here we need to check if a user has arrived here from a locality search
    // from outside of the refinement filter that isn't actually a suburb and
    // thus doesn't appear in our `localityTree`
    //
    // @NOTE: We could probably handle this better when the refinement filters
    // are better integrated with the "Search Form"
    //
    // @HACK: VueTree default value as 0 is pretty much required
    const value = this.values['suburb'] || this.values['location'] || 0
    const foundSuburb = suburbs.find(s => s.children.find(c => c.id === value))

    // If we are missing the locality, we will prepend it to the top of our final
    // suburbs tree so the user has something to select and doesn't revert to a
    // default display of the raw slug and an "(unknown)" count in the label
    const selectedValue = this.initialSelectedData.suburb.slug || this.initialSelectedData.location.slug || 0
    const selectedName = this.initialSelectedData.suburb.name || this.initialSelectedData.location.name || 'All Locations'
    if(selectedValue === 'all-locations' || value === 0) {
      suburbs.unshift({
        id: 0,
        label: 'All Locations'
      })
    } else if(!foundSuburb){
      suburbs.unshift({
        id: selectedValue,
        label: selectedName
      })
    }

    return {
      slug: 'suburb',
      value: value,
      template: 'tree.html',
      label: 'Refine Location',
      placeholder: "All Locations",
      children: suburbs,
      initial: true,
      valid: true,
    }
  }

  // Gets the nice version of the suburb value
  getLabel(slug) {
    const value = this.values[slug]
    // Note: suburbs are locations, yes its kinda confusing
    if (!value) return slug === 'suburb' ? 'All Locations' : `All ${capitalize(slug)}s`
    return this.labels[value] || value
  }

  // returns the page URL the user should be taken to when submitting the form
  get pageUrl() {

    const data = {...this.values}

    // Base Path, pull the correct location and classification
    const locationPath = (ENABLE_RADIUS_SEARCH ? data.suburb || data.location : data.location) || 'all-locations'
    const classificationPath = data.classification ? `${data.classification}/` : ''
    const basePath = `${locationPath}/${classificationPath}`

    // facetsPath, uses refined assets to possibly further add to the path
    const refinedValues = this.menuJson.refined
                              .map(f => data[f.slug]) // => String | Null
                              .filter(value => value)
    const facetsPath = refinedValues.length > 0
          ? `${refinedValues.join('/')}/`
          : ""

    // Construct a new query string from the existing one plus these values (if set):
    const queryValues = ['price', 'keywords', 'radius']
    const filteredValues = queryValues.concat(['page'])
    const filledValues = queryValues.filter(v => typeof data[v] !== 'undefined' || data[v])
    const searchData = Object.fromEntries(new URLSearchParams(window.location.search))
    let searchQuery = '?' + new URLSearchParams({
      ...omit(filteredValues, searchData),
      ...pick(filledValues, data)
    })
    if(searchQuery.toString() === '?') searchQuery = '';

    // Build the final URL string
    return window.BSS.baseUrl + basePath + facetsPath + searchQuery + DOMPurify.sanitize(window.location.hash)
  }
}
