
export default function utils() {
 // Listen to tab events to enable outlines (accessibility improvement)
  var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
  var currentclass = root.className + ' no-focus-outline'; // Add to existing classes

  root.setAttribute( 'class', currentclass);

  document.body.addEventListener('keyup', function(e) {
    if (e.which === 9) /* tab */ {
      document.documentElement.classList.remove('no-focus-outline');
    }
  });

  function initExpandables() {
    for (let elem of document.querySelectorAll('.pillars-accordion .pillar-item')) {
      const expand = elem.querySelector('.expand');
      const collapse = elem.querySelector('.collapse');

      if (expand)
        expand.addEventListener("click", (e) => {
          e.preventDefault();
          expand.closest('.expandable').classList.add('expanded');
        });

      if (collapse)
        collapse.addEventListener("click", (e) => {
          e.preventDefault();
          collapse.closest('.expandable').classList.remove('expanded');
        });

    }
  }
  initExpandables();
}

export function addQueryString(url, queryString) {
  if (queryString) {
    var isQuestionMarkPresent = url && url.indexOf('?') !== -1,
      separator = isQuestionMarkPresent ? '&' : '?';
    url += separator + queryString;
  }

  return url;
}
