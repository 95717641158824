import u from 'umbrellajs'
import { range } from 'rambda'
import { debounce } from 'rambdax'
import { onResize } from './Responsive'

const enhanceTopDeck = topDeck => {
  const inner = u(topDeck).find('.top-deck-inner').first()
  let scrollWidth = document.body.clientWidth - 40
  let totalWidth = inner.scrollWidth - 40

  // Enable Click for all cards with [click-url] set
  u('.card[data-click-url]', topDeck).on('click', function(event){
    // Exclude child elements that have their own click events from using click-url
    if(['BUTTON', 'A'].includes(event.target.tagName)){ return false; }

    // Omniture Tracking (Deprecated in favour of [data-omniture])
    if(window.NCA_OMNITURE_ENABLED !== true){
      const position = Array.from(inner.children).indexOf(this) + 1
      sessionStorage.setItem('eVar9', position)
    }

    // Do Navigation
    const href = u(this).data('click-url')
    window.location = href
  })

  // Mobile Scrolling Functionality
  const $left = u(topDeck).find('.deck-left')
  const $right = u(topDeck).find('.deck-right')

  const setScroll = offset => inner.scroll({left: offset, behaviour: 'smooth'})
  const adjustScroll = offset => () => setScroll(inner.scrollLeft + offset)
  const adjustLeft = adjustScroll((scrollWidth * -1))
  const adjustRight = adjustScroll(scrollWidth)

  // We will only bind when the elements are present
  if($left.length && $right.length){
    $left.handle('click', adjustLeft)
    $right.handle('click', adjustRight)
  }

  // Mobile paignation dots. Updates the DOM based on the current window state
  const $dots = u(topDeck).find('.pagination-dots')
  const renderDots = () => {
    if($dots.length === 0){ return; }
    const dotCount = Math.ceil(totalWidth / scrollWidth)
    const $elements = u(range(0, dotCount))
          .map(i => `<button value="${i}" />`)
          .handle('click', e => {
            setScroll((totalWidth / dotCount) * parseInt(e.target.value, 10))
            $dots.find('.active').removeClass('active')
            u(e.target).addClass('active')
          })
    $dots.children().remove()
    $dots.append($elements)
  }

  // Handle event for scrolling mobile scrolling
  let hideLeft = null
  let hideRight = null
  const handleInnerScroll = debounce(() => {
    const isLeftHidden = () => inner.scrollLeft <= 40
    const isRightHidden = () => inner.scrollLeft + 40 >= totalWidth - scrollWidth
    if(hideLeft != isLeftHidden()){ hideLeft = isLeftHidden(); $left.toggleClass('hidden', hideLeft) }
    if(hideRight != isRightHidden()){ hideRight = isRightHidden(); $right.toggleClass('hidden', hideRight) }
    $dots.find('.active').removeClass('active')
    const dotPos = Math.ceil( inner.scrollLeft / scrollWidth )
    $dots.find(`:nth-child(${dotPos + 1})`).addClass('active')
  }, 25)
  inner.addEventListener('scroll', handleInnerScroll, {passive : false})

  // When the document is finished loading or the size of the window changes we want
  // to resync the cards to ensure they are displaying correctly.
  const syncCards = viewport => {
    // Ensure we have the correct amount of dots
    scrollWidth = document.body.clientWidth - 40
    totalWidth = inner.scrollWidth - 40
    renderDots()
    handleInnerScroll()

    const cardCount = u(inner).find('.card').length
    if(cardCount > 0 && viewport === 'desktop'){
      // sidebar/ad-area resizer
      // @HACK: Due to a limition in grids there is not a way to simply set 1/($rows+2) so
      // we will do it here in JS to allow for a sidebar that consistently tracks the height
      // against the amount of cards being shown next to it.
      u(topDeck).find('.sidebar-area, .ad-area')
        .each(element => { element.style['grid-row'] = `1/${Math.ceil(cardCount / 3) + 2}` })

      // Calculate the true inner height by collecting the height of one card from
      // each row including the gap. If no cards, fallback to 300px which is about 1 card
      const cardHeights = u(topDeck).find('.top-deck-inner .card:nth-of-type(3n+1)')
            .array(e => e.clientHeight)
      const trueInner = cardHeights.length ? cardHeights.reduce((a,b) => a + b + 16) : 300;

      // @HACK: Now we can restrict recent-notices from growing beyond the cards
      u(topDeck).find('#recent-body').each(body => {
        body.style['max-height'] = `${trueInner - 126}px`
        body.style['height'] = `100%`
      })
    } else {
      u(topDeck).find('#recent-body').each(body => {
        body.style['max-height'] = null
        body.style['height'] = null
      })
    }
  }
  onResize(syncCards)
  syncCards()

  // Unbind/Unload
  return () => {
    u(inner).off(handleInnerScroll)
    $left.off(adjustLeft)
    $right.off(adjustRight)
    $dots.find('*').remove()
  }
}


export default function Cards(){
  // Initialise all top decks in their own closure
  const unloads = u('.top-deck').nodes.map(enhanceTopDeck)
  window.addEventListener('unload', unloads[0])
}
