export default function selectTargetTabMenu() {
    if (!window.location.hash && document.querySelectorAll("a.tab").length) {
      window.location.hash = document.querySelectorAll("a.tab")[0].getAttribute("href");
    }
    for (let element of document.querySelectorAll("a.tab")) {
        if (element.getAttribute("href") == window.location.hash) {
            element.parentElement.classList.add('active');
        } else {
            element.parentElement.classList.remove('active');
        }
    }
}

window.addEventListener("hashchange", () => {selectTargetTabMenu();}, false);
