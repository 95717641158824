import u from 'umbrellajs'
import axios from 'axios'

export default function Forms(){
  u('form.form-ajax').handle('submit', async function(e){
    const $form = u(e.target)
    const $alert = $form.find('.alert-block').removeClass(['alert-error', 'alert-success'])
    const result = await axios.post($form.attr('action'), new FormData(e.target), {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    if(result.data.errors && result.data.success !== true){
      const errors = Object.entries(result.data.errors).map(x => `<p>${x[0]}: ${x[1]}</p>`)
      const errorText = errors.reduce((a,b) => a + b)
      return $alert
        .addClass('alert-error')
        .html(`<b>${result.data.msg}</b><br/>${errorText}`)
        .first().scrollIntoView()
    }

    $alert.addClass('alert-success').text(result.data.msg).first().scrollIntoView()
  })
}
