export function valueOrSlug(value) {
  if (value) {
    if (typeof value === "string") {
      return value;
    } else {
      return value.slug;
    }
  }
}

// Takes a value such as '1-1', '-' or '1-' and returns a range settings object
export const getRangeValues = (value) => value && value.indexOf('-') > -1
  ? ({ val: value, min: value.split('-')[0], max: value.split('-')[1] })
  : ({ val: null , min: null               , max: null})

// A refined facet is one that isn't one of our "core" facets
export const isRefinedFacet = key => !['location', 'suburb', 'keywords', 'classification', 'price', 'radius'].includes(key)
