import Glider from './glider.js'


// @HACK: Remove this responsive javascript code, i see no actual logic being used based on it
export default class GliderCarousel {
  constructor(topPicksEl, gliderArrowsEl, gliderDotsEl, gliderArrowPrev, gliderArrowNext, topPickCardEl, showOnDesktop) {
    this.topPicksEl = topPicksEl;
    this.gliderArrowsEl = gliderArrowsEl;
    this.gliderArrowPrev = gliderArrowPrev;
    this.gliderArrowNext = gliderArrowNext;
    this.gliderDotsEl = gliderDotsEl;
    this.topPickCardEl = topPickCardEl;
    this.showOnDesktop = showOnDesktop || '';
    this.gliderTrackEl = '';
    this.removeStyles = true;

    if(!this.topPicksEl) {
      return;
    }

    this.createGliderCarousel();
    this.windowResizeEventListener();
    this.toggleView();
  }

  createGliderCarousel() {
    /*
      * This method is used to configure the Glider.js carousel
    */

    this.glider = new Glider(this.topPicksEl, {
      slidesToShow: 2.5,
      draggable: true,
      dots: this.gliderDotsEl,
      arrows: {
        prev: this.gliderArrowPrev,
        next: this.gliderArrowNext
      },
    });

    this.gliderTrackEl = this.topPicksEl.querySelector('.glider-track');
  }

  windowResizeEventListener() {
    /*
      *This method is used to add event listener to the DOM window size
    */
    window.addEventListener('resize', () => {
      this.toggleView();
    });
  }

  toggleView() {
    /*
      *	This method is used to toggle between the mobile carousel
      * and the desktop grid, removing the inline styles added by glider.js
    */
    if(window.innerWidth > 767) {
      if(this.removeStyles && !this.showOnDesktop) {
        this.removeInlineStyles();
      }
    } else {
      this.removeStyles = true;
    }
  }

  removeInlineStyles() {
    // Remove the inline styles applied by glider
    if(this.gliderTrackEl) { this.gliderTrackEl.style = ''; }
    for(var i = 0; i < this.topPickCardEl.length; i++) {
      this.topPickCardEl[i].style = '';
    }
    this.removeStyles = false;
  }
}
