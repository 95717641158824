import u from 'umbrellajs'

const AUTOSCROLL_PERIOD = 5000 // in miliseconds

export default function HeroCarousel(){
  const $carousel = u('.hero-carousel')
  const $panes = $carousel.children('div,img')
  if($carousel.length !== 1){ return console.debug('There can be only 1 carousel!', $carousel); }
  if($panes.length <= 1){ return console.debug("Only 1 slide, don't load carousel functionality"); }

  let autoscroll = true
  let current = 0

  // Create and mount dots
  const $dots = u('<div class="pagination-dots large mono" />');
  [...Array($panes.length).keys()].forEach(i => {
    $dots.append(u(`<button class="${i === 0 && 'active'}" />`).handle('click', () => doScroll(i, false)))
  })
  $carousel.after($dots)

  // Bind and enable Left/Right Buttons
  const goForward = auto => doScroll(current >= $panes.length - 1 ? 0 : current + 1, auto)
  const goBack = auto => doScroll(current <= 0 ? $panes.length - 1 : current - 1, auto)
  $carousel.parent().find('.hero-left').handle('click', () => goBack(false)).removeClass('hidden')
  $carousel.parent().find('.hero-right').handle('click', () => goForward(false)).removeClass('hidden')

  // Main Scroll loop
  const doScroll = async (targetPos = false, auto = true) => {
    if(auto && !autoscroll){ return console.debug('Cancel autoscroll, user interaction') }
    if(targetPos == current && !auto){ return console.debug('Cancel user scroll, aready here!') }
    autoscroll = auto

    // Handle Scroll logic
    current = targetPos
    $carousel.nodes[0].scroll({left: document.body.clientWidth * current, behaviour: 'smooth'})
    $dots.find('.active').removeClass('active')
    u($dots.children().nodes[current]).addClass('active')

    // Get exited for next scroll
    await new Promise(res => setTimeout(res, AUTOSCROLL_PERIOD))
    if(autoscroll){ goForward(true) }
  }

  window.addEventListener('load', () => setTimeout(() => doScroll(1), AUTOSCROLL_PERIOD))
}
